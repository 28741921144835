// Used to tell parent window that the child is ready for the setup event
import * as t from "typed-assert";

export interface BaseEvent {
  type: string;
}

export interface ReadyEvent extends BaseEvent {
  type: "ready";
}

type SetupData = {
  customerId?: string;
  accessToken?: string;
} & Record<string, any>;

export interface SetupEvent extends BaseEvent {
  type: "setup";
  data: SetupData;
}

export interface ResizeData {
  width: number;
  height: number;
}

export interface ResizeEvent extends BaseEvent {
  type: "resize";
  data: ResizeData;
}

// Used to tell parent window that the child window is finished and we can close it.
export interface ExitEvent extends BaseEvent {
  type: "exit";
}

export function sendReadyEvent(): ReadyEvent {
  const event: ReadyEvent = { type: "ready" };
  window.parent.postMessage(event, "*");
  return event;
}

export function sendResizeEvent(size: ResizeData): ResizeEvent {
  const event: ResizeEvent = { type: "resize", data: size };
  window.parent.postMessage(event, "*");
  return event;
}

export function sendExitEvent(): ExitEvent {
  const event: ExitEvent = { type: "exit" };
  window.parent.postMessage(event, "*");
  return event;
}

function assertEvent(event: unknown): asserts event is BaseEvent {
  t.isRecord(event);
  t.isString(event.type);
}

function assertSetupEvent(event: unknown): asserts event is SetupEvent {
  t.isRecord(event);
  t.isExactly(event.type, "setup");
  t.isRecord(event.data);
}

export function onSetupEvent(callback: (event: SetupEvent) => void): void {
  window.addEventListener("message", (messageEvent: MessageEvent<unknown>) => {
    const event = messageEvent.data;
    try {
      assertEvent(event);
    } catch (e) {
      // Ignore event not for us
      return;
    }
    if (event.type !== "setup") {
      return;
    }
    assertSetupEvent(event);
    callback(event);
  });
}
