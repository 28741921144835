<script setup lang="ts">
import { ref } from "vue";
import { OpenAPI as OpenAPIBAS } from "../api.generated";
import { OpenAPI as OpenAPISCION } from "../api/scion";
import { OpenAPI as OpenAPIBAAS } from "../api/brand-approval-application-service";
import "@wegift/company-brand-assets/dist/design-system/css/font-f37Lineca.css";
import "@wegift/two-components/dist/style.css";
import "../tailwind.css";
import { useGlobalStore } from "../stores/global";
import { useProductStore } from "../stores/product";
import { onSetupEvent } from "../parent-interface";
import { ProductCodeToNameMap } from "../utils/types";
import "../icons";

OpenAPIBAS.BASE = import.meta.env.VITE_BRAND_APPROVAL_SERVICE_BASE_URL;
OpenAPIBAAS.BASE =
  import.meta.env.VITE_BRAND_APPROVAL_APPLICATION_SERVICE_BASE_URL;

const globalStore = useGlobalStore();
const productStore = useProductStore();
const containerStyle = ref(
  "bg-grey-50 container bap-container w-full py-10 px-4 md:px-6 xl:px-8"
);
const isConnect = ref(true);

OpenAPIBAS.BASE = import.meta.env
  .VITE_BRAND_APPROVAL_SERVICE_BASE_URL as string;
OpenAPISCION.BASE = import.meta.env.VITE_SCION_BASE_URL as string;
OpenAPIBAAS.BASE =
  import.meta.env.VITE_BRAND_APPROVAL_APPLICATION_SERVICE_BASE_URL;

if (globalStore.brandApprovalAccessToken) {
  OpenAPIBAS.TOKEN = globalStore.brandApprovalAccessToken;
  // @ts-ignore
  OpenAPISCION.TOKEN = globalStore.token;
  OpenAPIBAAS.TOKEN = globalStore.brandApprovalAccessToken;
  isConnect.value = false;
}

onSetupEvent(async (event) => {
  OpenAPIBAS.TOKEN = event.data.accessToken;
  OpenAPIBAAS.TOKEN = event.data.accessTokenBAAS;
  OpenAPISCION.TOKEN = event.data.accessToken;
  globalStore.setGlobalData(event.data.customerId as string);
  productStore.productCodesAndNames = event.data
    .productCodesAndNames as ProductCodeToNameMap;
  containerStyle.value = "bg-grey-50 p-6";
  isConnect.value = true;
});
</script>

<template>
  <div
    class="container mx-auto flex h-screen items-center justify-center"
    v-if="
      !isConnect
        ? !globalStore.customerId || !globalStore.brandApprovalAccessToken
        : !globalStore.customerId
    "
  >
    Please select customer
  </div>
  <main v-else :class="containerStyle">
    <router-view :isConnect="isConnect" />
  </main>
</template>
